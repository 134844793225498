<template>
    <ModalTemplate modalID="resetPassword">
        <div class="modal fade" id="resetPassword" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4" :class="{'border-0' : !isValid}">
                        <h3 v-if="isValid" class="modal-title text-center w-100">Reset Your Password</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5" :class="{'pt-0 pt-md-0' : !isValid}">

                        <!-- Reset -->
                        <div v-if="isValid">
                            <form @submit.prevent="handleResetPassword">
                                <div class="mb-3">
                                    <label for="user-name" class="form-label">Username</label>
                                    <input type="text" class="form-control form-control-lg" id="user-name" v-model="username" disabled>
                                </div>

                                <div class="mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control form-control-lg" id="email" v-model="email" disabled>
                                </div>

                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control form-control-lg" :class="{'is-invalid':invalid.password.length > 0}" id="password" v-model="password">
                                    <div class="invalid-feedback">{{invalid.password}}</div>
                                </div>

                                <div>
                                    <label for="c-password" class="form-label">Confirm Password</label>
                                    <input type="password" class="form-control form-control-lg" :class="{'is-invalid':invalid.confirm_password.length > 0}" id="c-password" v-model="confirm_password">
                                    <div class="invalid-feedback">{{invalid.confirm_password}}</div>
                                </div>

                                <button type="submit" class="btn btn-lg btn-main-1 text-white w-100 mt-4">Submit</button>
                            </form>
                        </div>

                        <!-- Link Invalid -->
                        <div v-else>
                            <img class="modal-icon mb-5" src="@/assets/img/Exclamation-triangle.png" alt="">
                            
                            <p class="text-secondary text-center lh-lg">
                                The link you followed has expired.<br>
                                Please submit your email address again for obtaining a new link.
                            </p>

                            <div>
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control form-control-lg" id="email" v-model="resendEmail">
                            </div>

                            <button class="btn btn-lg btn-main-1 text-white w-100 mt-4" @click="sendForget()">Get A New Link</button>

                        </div>                  
                    </div>

                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import { mapState , mapMutations } from 'vuex'
import { getUrlParams , removeUrlParams , getErrorMessage } from '@/assets/js/common.js';
import ModalTemplate from "@/components/modals/ModalTemplate.vue";

export default {
    name: 'resetPassword',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            token:"",
            isValid: false, 

            resendEmail:"",
            username:"",
            email:"",
            password:"",
            confirm_password:"",
            
            invalid:{
                resendEmail:"",
                password:"",
                confirm_password:"",
            },
        }
    },
    computed:{
        ...mapState(['modals'])
    },
    methods:{
        ...mapMutations(['set_page_loading']),

        handleResetPassword() {
            this._reCAPTCHA((token) => {
                this.resetPassword(token)
            })
        },

        resetPassword(google_recaptcha_token){
            this.set_page_loading(true);
            this.clearInvalid();

            let data = {
                password: this.password,
                confirm_password: this.confirm_password,
                google_recaptcha_token
            }

            this.axios.post('https://binaryflash.codewcy.com/api/site/forgot_password/' + this.token, data).then(response => {
                
                this.set_page_loading(false);
                if(response.data.status === 'success'){
                    this.modals.resetPassword.hide();
                    swal({
                        title: response.data.message,
                        icon: 'success',
                        button: "OK",
                    }).then(()=>{
                        this.$router.replace({ name: this.$route.name });
                    });

                }else {
                    let { errorMsg, errorObj } = getErrorMessage(response.data.message);
                    if(errorMsg === 'Invalid parameter'){
                        for(let key in errorObj){
                            this.invalid[key] = errorObj[key][0];
                        }
                    }else {
                        swal({
                            title: errorMsg,
                            icon: 'error',
                            button: "OK",
                        }).then(()=>{
                            this.$router.replace({ name: this.$route.name});
                        });
                    }
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })

        },

        sendForget(){
            this.set_page_loading(true);
            this.clearInvalid();

            let data = {
                email: this.resendEmail,
            }

            this.axios.post('https://binaryflash.codewcy.com/api/site/send_forgotten_email', data).then(response => {
                this.set_page_loading(false);

                if(response.data.status === 'success'){
                    setCookie('tempEmail', this.resendEmail);
                    this.modals.resetPassword.hide();
                    this.modals.sentReset.show();

                }else if(response.data.status === 'e401'){
                    let { errorMsg, errorObj } = getErrorMessage(response.data.message);
                    
                    if(errorMsg === 'Invalid parameter'){
                        for(let key in errorObj){
                            this.invalid[key] = errorObj[key][0];
                        }
                    }else {
                        swal({
                            title: errorMsg,
                            icon: 'error',
                            button: "OK",
                        })
                    }
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })


        },

        clearInvalid(){
            for(let key in this.invalid){
                this.invalid[key] = "";
            }
        },

    },
    mounted() {
        let event = getUrlParams('event');
        let token =  getUrlParams('token');

        if(event === 'forgot_password' && token){
            this.set_page_loading(true);
            this.token = token;

            // FIXME: 待第三方 js 載入完畢
            setTimeout(() => {
                this._reCAPTCHA((google_recaptcha_token) => {
                    let data = {
                        google_recaptcha_token
                    }

                    this.axios.get('https://binaryflash.codewcy.com/api/site/forgot_password/' + token, {
                        params: data
                    }).then(response => {
                        this.set_page_loading(false);

                        if (response.data.status === 'success') {
                            this.isValid = true;
                            this.username = response.data.result.name;
                            this.email = response.data.result.email;

                        } else {
                            this.isValid = false;
                        }

                        this.modals.resetPassword.show();

                    }).catch((e) => {
                        this._handleCatch(e)
                    })
                })
            }, 1000);
		}
    },
    
}
</script>