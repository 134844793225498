import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { deleteCookie } from '@/assets/js/common.js';
import { getCookie } from '@/assets/js/common.js';
import swal from 'sweetalert';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pageLoading:false,
		member:{
			isLogin:false,
			email:"",
			name:"",
			timezone:"",
			email_verified: false
		},
		modals:{}
	
	},
	mutations: {
		set_page_loading(state, isLoading){
			state.pageLoading = isLoading;
		},

		set_login (state,  { isLogin, email, name, timezone, email_verified }) {

			state.member.isLogin = isLogin;
			if(isLogin){
				state.member.email = email;
				state.member.name = name;
				state.member.timezone = timezone;
				state.member.email_verified = email_verified;

			}else{
				state.member.email = "";
				state.member.name = "";
				state.member.timezone = "";
				state.member.email_verified = false;
			}
		},

		set_modal(state, {modalName, modalValue}){
			state.modals[modalName] = modalValue;
		}

	},
	actions: {

		async logout({ commit }){
			commit('set_page_loading', true);
			let token = getCookie('token');

			const config = {
				headers: { Authorization: `Bearer ${token}` }
			};

			await axios.post("https://binaryflash.codewcy.com/api/site/logout", {} , config).then(response => {
				
				commit('set_page_loading', false);
				if(response.data.status === 'success'){
					deleteCookie('token');
					commit('set_login', { isLogin:false });
					window.location.reload();
				}
			}).catch((e)=>{
                throw e.response
            })

				
		},

		async getMember({ commit , state }){
			commit('set_page_loading', true);
			let token = getCookie('token');
			const config = {
				headers: { 
					Authorization: `Bearer ${token}`,
					Accept: 'application/json'
				}
			};

		await axios.get("https://binaryflash.codewcy.com/api/site/member" , config).then(response => {
				commit('set_page_loading', false);
				if(response.data.status === 'success'){
					let { email , name , timezone , email_verified } = response.data.result.member;

					commit('set_login',{
						isLogin:true,
						email: email,
						name: name,
						timezone: timezone,
						email_verified: email_verified,
					});
					
					//if email is not verified, show emailVerification modal
					if(!email_verified){
						state.modals.emailVerification.show();
					}

				}else{
					commit('set_login', { isLogin:false });
				}
			}).catch((e)=>{
                throw e.response
            })
			
		},

	},

})
