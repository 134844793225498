<template>
    <ModalTemplate modalID="forgetPassword">
        <div class="modal fade" id="forgetPassword" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Forget Password</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5">
                        <form @submit.prevent="handleSendForget">
                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control form-control-lg" :class="{'is-invalid':invalid.email.length > 0}" v-model="email" id="email">
                                <div class="invalid-feedback">{{invalid.email}}</div>
                            </div>
                            <button type="submit" class="btn btn-lg btn-main-1 text-white w-100">Reset Password</button>
                        </form>
                    </div>
                    <div class="modal-footer justify-content-center p-3 p-md-4">
                        Not a member? 
                        <span class="cursor-pointer text-main-1 ms-2 fw-bold" @click="showSignup()">Sign up now</span>
                    </div>

                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import { mapState , mapMutations} from 'vuex'
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import { setCookie , getErrorMessage } from '@/assets/js/common.js';

export default {
    name: 'forgetPassword',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            email:"",
            invalid:{
                email:"",
            },
        }
    },
    computed:{
        ...mapState(['modals'])
    },
    methods:{
        ...mapMutations(['set_page_loading']),

        showSignup(){
            this.modals.forgetPassword.hide();
            this.modals.signup.show();
        },

        handleSendForget() {
            this._reCAPTCHA((token) => {
                this.sendForget(token)
            })
        },

        sendForget(google_recaptcha_token){
            this.set_page_loading(true);

            let data = {
                email: this.email,
                google_recaptcha_token
            }

            this.axios.post('https://binaryflash.codewcy.com/api/site/send_forgotten_email', data).then(response => {
                this.set_page_loading(false);

                if(response.data.status === 'success'){
                    setCookie('tempEmail', this.email);
                    this.modals.forgetPassword.hide();
                    this.modals.sentReset.show();

                }else if(response.data.status === 'e401'){
                    let { errorMsg, errorObj } = getErrorMessage(response.data.message);
                    
                    if(errorMsg === 'Invalid parameter'){
                        for(let key in errorObj){
                            this.invalid[key] = errorObj[key][0];
                        }
                    }else {
                        swal({
                            title: errorMsg,
                            icon: 'error',
                            button: "OK",
                        })
                    }
                    
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })

            
        },
    },
    mounted() {
        let forgetPassword = document.getElementById('forgetPassword');
        forgetPassword.addEventListener('hidden.bs.modal',() => {
            this.email = "";
            this.invalid.email = "";
        })
    },
}
</script>