<template>
  <nav id="main-navbar" class="navbar navbar-expand-lg navbar-dark fixed-top" :class="{'show' : showCollapse}">
    <div class="container-fluid px-0 px-lg-3">
		<router-link class="navbar-brand ms-3" :to="{ name: 'HomePage' }">
			<img src="@/assets/img/BinaryFlash.png" alt="logo" height="24" class="d-inline-block align-text-top">
		</router-link>

		<button id="navbar-toggler" class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNav">
			
			<ul class="navbar-nav">
				<li class="nav-item" @click="setNavbarDefault()">
					<router-link :to="{ name: 'About' }" class="nav-link text-white text-center mx-lg-4 py-4 py-lg-0 border-bottom">About</router-link>
				</li>
				<li class="nav-item" @click="setNavbarDefault()">
					<router-link :to="{ name: 'Plan' }" class="nav-link text-white text-center mx-lg-4 py-4 py-lg-0 border-bottom">Plan</router-link>
				</li>
				<li class="nav-item" @click="setNavbarDefault()">
					<router-link :to="{ name: 'Contact' }" class="nav-link text-white text-center mx-lg-4 py-4 py-lg-0 border-bottom">Contact Us</router-link>
				</li>
				<li class="nav-item" @click="setNavbarDefault()">
					<router-link :to="{ name: 'Terms' }" class="nav-link text-white text-center mx-lg-4 py-4 py-lg-0 border-bottom">Terms & Conditions</router-link>
				</li>
			</ul>

			<div v-if="member.isLogin" id="member-nav" @mouseover="showMemberNav = true" @mouseleave="showMemberNav = false">
				<span id="member-dropdown-toggler" class="ms-auto d-none d-lg-block">
					<img class="img-fluid" src="@/assets/img/member-circle.png" alt="">
				</span>
				<ul class="navbar-nav bg-main-2" :class="{'hide' : !showMemberNav}">
					<li class="nav-item disabled">
						<span class="nav-link text-white text-center py-4 py-lg-0 border-bottom">{{member.name}}</span>
					</li>

					<li v-if="!member.email_verified" class="nav-item">
						<span class="cursor-pointer nav-link text-color-highlight text-center py-4 py-lg-0 border-bottom" @click="modals.emailVerification.show();setNavbarDefault();">Email Verification</span>
					</li>

					<li class="nav-item">
						<router-link :to="{ name: 'Plan' }" class="nav-link text-white text-center py-4 py-lg-0 border-bottom" @click="setNavbarDefault()">Current Plan</router-link>
					</li>
					<li class="nav-item">
						<span class="cursor-pointer nav-link text-white text-center py-4 py-lg-0 border-bottom" @click="handleLogout">Logout</span>
					</li>
				</ul>
			</div>
			
			<span v-else class="cursor-pointer nav-link text-white text-center py-4 py-lg-0 ms-auto"  data-bs-toggle="modal" data-bs-target="#login" @click="setNavbarDefault();">Login</span>
			
		</div>
    </div>
  </nav>
</template>

<script>
import { mapState , mapActions } from 'vuex';

export default {
	name: 'Navbar',
	data(){
		return{
			showCollapse: false,
			showMemberNav: false
		}
	},
	computed:{
		...mapState(['member' , 'modals'])
	},
	methods:{

		...mapActions(['logout']),

		async handleLogout() {
			await this.logout();

			this.setNavbarDefault();
		},

		setNavbarDefault(){
			document.querySelector('#main-navbar .navbar-collapse').classList.remove('show');
			document.querySelector('#main-navbar #navbar-toggler').classList.add('collapsed');
			
			this.showCollapse = false;
			this.showMemberNav = false;
		}
	},
	mounted(){
		let navbarNav = document.getElementById('navbarNav');
		navbarNav.addEventListener('show.bs.collapse', () => {
			this.showCollapse = true;
		})	

		navbarNav.addEventListener('hide.bs.collapse', () => {
			this.showCollapse = false;
		});

		window.addEventListener('resize', this.setNavbarDefault);
		window.addEventListener('scroll', ()=>{
			if(window.scrollY > 20){
				document.querySelector('#main-navbar').classList.add('overlay')
			}else{
				document.querySelector('#main-navbar').classList.remove('overlay')
			}
			
		});
		
	}

}
</script>

<style lang="scss" scoped>

	@import "@/scss/all.scss";

	.navbar{
		transition: background-color .5s;


		&.show{
			background-color: $main-3;
			transition: background-color .5s;

			.navbar-brand{
				display: none;
			}

			.navbar-toggler-icon{
				background-image:url(~@/assets/img/nav-close.svg);
				width: 1rem;
			}
		}

		@include media-breakpoint-up(lg) { 
			&.overlay{
				background-color: #00000050;
				transition: .3s;
			}
		}

	}

	.navbar-toggler{
		border: 0;
		z-index: 1;

		&:focus{
			box-shadow: none;
		}
		
	}

	.navbar-nav{

		@include media-breakpoint-up(lg) { 
			flex-grow: 1;
			justify-content: center;
			margin-left: -100px;
		}
		
	}

	.navbar-brand{
		z-index: 1;
	}

	.nav-link{
		
		@include media-breakpoint-up(lg) { 
			&.border-bottom{
				border-color: transparent !important;
			}
		}

		@include media-breakpoint-down(lg) { 
			&.border-bottom{
				border-color: rgba(255, 255, 255, 0.15) !important;
			}
		}
	}

	#member-nav{
		margin-bottom: -0.5rem;
		
		@include media-breakpoint-up(lg) { 
			position: relative;
			
			#member-dropdown-toggler{
				cursor: pointer;
				width: 56px;	
			}

			.navbar-nav{
				display: flex;
				flex-direction: column;
				align-items: center;
				position: absolute;
				right: 0;
				top: 56px;
				box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
				border-radius: 10px;
				padding: 10px 0;
				min-width: 200px;

				&.hide{
					display: none;
				}

				.nav-item{
					width: 100%;

					.nav-link{
						white-space: nowrap;
						padding: 8px 16px !important;
					}

					&:not(.disabled) .nav-link{
						&:hover{
							opacity: .8;
						}
					}

					
					&:first-child .nav-link{
						margin-bottom: 10px;
						padding-bottom: 15px !important;
						border-color: rgba(255, 255, 255, 0.15) !important;

					}
					
				}

			}

		}

	}

</style>
