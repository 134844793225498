<template>
    <ModalTemplate modalID="emailVerified">
        <div class="modal fade" id="emailVerified" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Email Address Verified</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5 text-center">
                        <p class="text-secondary lh-lg mb-4">
                            Thank you, your email: <br>
                            <span class="text-dark fw-bold">{{email}}</span><br>                        
                            has been verified, please click the button below to login BinaryFlash.
                        </p>
                        <button class="btn btn-lg btn-main-1 text-white w-100" data-bs-dismiss="modal" @click="showLogin()">Login</button>
                    </div>

                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import { mapState , mapMutations} from 'vuex'
import { getUrlParams , removeUrlParams } from '@/assets/js/common.js';
import ModalTemplate from "@/components/modals/ModalTemplate.vue";

export default {
    name: 'emailVerified',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            email:""
        }
    },
    computed:{
        ...mapState(['modals'])
    },
    methods:{       
        ...mapMutations(['set_page_loading']),

        showLogin(){
            this.modals.emailVerified.hide();
            this.modals.login.show();
        },
    },
    mounted() {
        
        let event = getUrlParams('event');
        
        if(event === 'verify_email'){
            this.set_page_loading(true);
            let token = getUrlParams('token');
            if(token){
                this.axios.post('https://binaryflash.codewcy.com/api/site/verify_email/' + token).then(response => {
                    
                    this.set_page_loading(false);
                    removeUrlParams();
                    
                    if(response.data.status === 'success'){
                        this.email = response.data.result.email;
                        this.modals.emailVerified.show();
                        
                    }else{
                        swal({
                            title: response.data.message,
                            icon: 'error',
                            button: "OK",
                        })
                        
                    }
                }).catch((e)=>{
                    this._handleCatch(e)
                })

            }

		}
    },

}
</script>