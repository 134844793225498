import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuex from 'vuex';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';

import './scss/all.scss';
import 'bootstrap';
import 'sweetalert';

import { getCookie, deleteCookie, configs } from '@/assets/js/common.js';

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

Vue.use(VueAxios, axios, Vuex);
Vue.config.productionTip = false;

// ===== custom axios =====
const customAxios = axios.create({
  baseURL: 'https://binaryflash.codewcy.com/api/site',
  // 接受所有狀態自行處理
  validateStatus: () => true
});

customAxios.interceptors.request.use(config => {
  const token = getCookie('token');

  if (!!token) {
    config.headers['authorization'] = `Bearer ${token}`;
  }

  return config;
});

customAxios.interceptors.response.use(res => {
  // http status＆body
  const { status, data } = res;

  if (status >= 400 || data.status !== 'success') {
    throw res;
  }

  return res;
});

Vue.mixin({
  data() {
    return {
      customAxios
    };
  },

  methods: {
    _reCAPTCHA(fn) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(configs.google_recaptcha_v3_site_key, {
            action: 'validate_captcha'
          })
          .then(token => {
            return fn(token);
          });
      });
    },

    _handleCatch(e) {
      handleError(e.response, this);
    }
  }
});
// ==========

Vue.config.errorHandler = function(err, vm, info) {
  // console.log(err);
  // debugger;

  handleError(err, vm);
};

function handleError(err, vm) {
  store.commit('set_page_loading', false);

  switch (err.status) {
    case 401:
      vm.modals.login.show();

      deleteCookie('token');

      if (vm.$route.path !== '/') {
        vm.$router.push('/');
      }

      swal({
        text: err.data.message,
        icon: 'error',
        button: 'OK'
      });

      break;

    case 200:
      swal({
        text: err.data.message,
        icon: 'error',
        button: 'OK'
      });

      break;

    default:
      swal({
        text: 'system is being maintained',
        icon: 'error',
        button: 'OK'
      });

      break;
  }
}

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  }
}).$mount('#app');
