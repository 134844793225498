<template>
  <div id="app">
    <Navbar></Navbar>

    <main class="page-body">
      <router-view />
    </main>

    <Footer></Footer>

    <Login></Login>
    <Signup></Signup>
    <SignupSuccess></SignupSuccess>
    <EmailVerified></EmailVerified>
    <EmailVerification></EmailVerification>
    <ResetPassword></ResetPassword>
    <ForgetPassword></ForgetPassword>
    <SentReset></SentReset>

    <PageLoading></PageLoading>
  </div>
</template>

<script>
import Navbar from '@/components/layouts/Navbar.vue';
import Footer from '@/components/layouts/Footer.vue';
import PageLoading from '@/components/layouts/PageLoading.vue';
import Login from '@/components/modals/Login.vue';
import Signup from '@/components/modals/Signup.vue';
import SignupSuccess from '@/components/modals/SignupSuccess.vue';
import EmailVerified from '@/components/modals/EmailVerified.vue';
import EmailVerification from '@/components/modals/EmailVerification.vue';
import ResetPassword from '@/components/modals/ResetPassword.vue';
import ForgetPassword from '@/components/modals/ForgetPassword.vue';
import SentReset from '@/components/modals/SentReset.vue';

import { mapActions, mapState } from 'vuex';
import { getCookie, configs } from '@/assets/js/common.js';

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    PageLoading,
    Login,
    Signup,
    SignupSuccess,
    EmailVerified,
    EmailVerification,
    ResetPassword,
    ForgetPassword,
    SentReset
  },

  computed: {
    ...mapState(['modals'])
  },

  methods: {
    ...mapActions(['getMember'])
  },

  async created() {
    const res = await this.customAxios.get('/configs');

    const {
      google_analytics_v4,
      google_recaptcha_v3_site_key,
      paypal_client_id
    } = res.data.result;

    Object.assign(configs, res.data.result);

    await this.$loadScript(
      `https://www.googletagmanager.com/gtag/js?id=${google_analytics_v4}`
    ).catch(() => {
      swal({
        text: 'The gtag of this website has been blocked by one or more third-party applications. Please unblock or add this website to whitelist in order for the website to function normally.',
        icon: 'error',
        button: 'OK'
      });
    });

    const scriptTag = document.createElement('script');

    scriptTag.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', '${google_analytics_v4}');
    `;

    document.querySelector('body').prepend(scriptTag);

    this.$loadScript(
      `https://www.google.com/recaptcha/api.js?render=${google_recaptcha_v3_site_key}`
    ).catch(() => {
      swal({
        text: 'The reCaptcha verification mechanism of this website has been blocked by one or more third-party applications. Please unblock or add this website to whitelist in order for the website to function normally.',
        icon: 'error',
        button: 'OK'
      });
    });

    this.$loadScript(
      `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription`
    ).catch(() => {
      swal({
        text: 'PayPal payment failed, please try again later.',
        icon: 'error',
        button: 'OK'
      });
    });
  },
  async mounted() {
    const token = getCookie('token');
    if (token) {
      await this.getMember();
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/all.scss';
body {
  background-image: url(~@/assets/img/Img_BG_01.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-body {
  overflow-x: hidden;
  min-height: calc(100vh - 56px);

  @include media-breakpoint-up(lg) {
    margin-top: 56px;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 56px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
</style>
