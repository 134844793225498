<template>
    <ModalTemplate modalID="emailVerification">
        <div class="modal fade" id="emailVerification" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Email Verification</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5 text-center">
                        <p class="text-secondary lh-lg mb-4">
                            You have not verified your email address yet.<br><br>

                            Please check your email inbox (<span class="text-dark fw-bold">{{member.email}}</span>) for on E-mail from BinaryFlash and follow the instructions provided, or click the button below to resend the verification email.
                        </p>
                        <button class="btn btn-lg btn-main-1 text-white w-100" @click="resendValidation()">Resend</button>
                    </div>

                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import { getCookie } from '@/assets/js/common.js';

export default {
    name: 'emailVerification',
    components: {
		ModalTemplate,
	},

    computed:{
        ...mapState(['modals', 'member'])
    },
    methods:{        
        ...mapMutations(['set_page_loading']),

        resendValidation(){

            this.set_page_loading(true);
            let token = getCookie('token');
            const config = {
				headers: { Authorization: `Bearer ${token}` }
			};

            this.axios.post('https://binaryflash.codewcy.com/api/site/send_validation_email', {}, config).then(response => {
                swal({
                    title: response.data.message,
                    icon: response.data.status === 'success' ? 'success' : 'error',
                    button: "OK",
                })

                this.modals.emailVerification.hide();
                this.set_page_loading(false);

            }).catch((e)=>{
                this._handleCatch(e)
            })

        },
    },

}
</script>