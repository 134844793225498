

<template>
    <ModalTemplate modalID="login">
        <template>
            <div class="modal fade" id="login" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header p-3 p-md-4">
                            <h3 class="modal-title text-center w-100">Login to BinaryFlash</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-3 p-md-5">
                            <form @submit.prevent="handleLogin">
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control form-control-lg" :class="{'is-invalid':invalid.email.length > 0}" id="email" v-model="email">
                                    <div class="invalid-feedback">{{invalid.email}}</div>
                                </div>

                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control form-control-lg" :class="{'is-invalid':invalid.password.length > 0}" id="password" v-model="password">
                                    <div class="invalid-feedback">{{invalid.password}}</div>
                                </div>

                                <div class="mb-3">
                                    <span class="text-main-1 cursor-pointer" @click="showForget()">Forgot Password?</span>
                                </div>

                                <button type="submit" class="btn btn-lg btn-main-1 text-white w-100">Login</button>
                            </form>
                        </div>

                        <div class="modal-footer justify-content-center p-3 p-md-4">
                            Not a member? 
                            <span class="text-main-1 ms-2 fw-bold cursor-pointer" @click="showSignup()">Sign up now</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ModalTemplate>
    
</template>

<script>
import { mapState , mapActions , mapMutations} from 'vuex'
import { setCookie , getErrorMessage } from '@/assets/js/common.js';
import ModalTemplate from "@/components/modals/ModalTemplate.vue";

export default {
    name: 'login',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            email:"",
            password:"",

            invalid:{
                email:"",
                password:"",   
            },
        }
    },
    computed:{
        ...mapState(['modals'])
    },
    methods:{
        
        ...mapActions(['getMember']),
        ...mapMutations(['set_page_loading']),

        handleLogin() {
            this._reCAPTCHA((token) => {
                this.login(token)
            })
        },

        login(google_recaptcha_token){
            this.set_page_loading(true);
            this.clearInvalid();

            let data = {
                email: this.email,
                password: this.password,
                google_recaptcha_token
            }

            this.axios.post('https://binaryflash.codewcy.com/api/site/login', data).then(async(response) => {
                
                this.set_page_loading(false);
                
                if(response.data.status === 'success'){
                    setCookie('token', response.data.result.access_token);
                    await this.getMember();
                    this.modals.login.hide();

                }else {
                    let { errorMsg, errorObj } = getErrorMessage(response.data.message);
                    
                    if(errorMsg === 'Invalid parameter'){
                        for(let key in errorObj){
                            this.invalid[key] = errorObj[key][0];
                        }
                    }else {
                        swal({
                            title: errorMsg,
                            icon: 'error',
                            button: "OK",
                        })
                    }
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })


            
        },
        
        showSignup(){
            this.modals.login.hide();
            this.modals.signup.show();
        },

        showForget(){
            this.modals.login.hide();
            this.modals.forgetPassword.show();
        },

        clearInvalid(){
            for(let key in this.invalid){
                this.invalid[key] = "";
            }
        },

        clearInputs(){
            this.email = "";
            this.password = "";
        }
    },
    mounted() {
        let login = document.getElementById('login');
        login.addEventListener('hidden.bs.modal',() => {
            this.clearInvalid();
            this.clearInputs();
        })
    },
}
</script>