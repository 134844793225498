

<template>
    <ModalTemplate modalID="signup">
        <div class="modal fade" id="signup" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Sign Up to BinaryFlash</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5">
                        <form @submit.prevent="handleSignup">
                            <div class="row mb-3">
                                <div class="col-md-6 mb-3">
                                    <label for="user-name" class="form-label">Username</label>
                                    <input type="text" class="form-control form-control-lg" :class="{'is-invalid':invalid.name.length > 0}" id="user-name" v-model="name">
                                    <div class="invalid-feedback">{{invalid.name}}</div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control form-control-lg" :class="{'is-invalid':invalid.email.length > 0}" id="email" v-model="email">
                                    <div class="invalid-feedback">{{invalid.email}}</div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control form-control-lg" :class="{'is-invalid':invalid.password.length > 0}" id="password" v-model="password">
                                    <div class="invalid-feedback">{{invalid.password}}</div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="c-password" class="form-label">Confirm Password</label>
                                    <input type="password" class="form-control form-control-lg" :class="{'is-invalid':invalid.confirm_password.length > 0}" id="c-password" v-model="confirm_password">
                                    <div class="invalid-feedback">{{invalid.confirm_password}}</div>
                                </div>


                                <div class="col-md-6 mb-3">
                                    <label for="timezone" class="form-label">Timezone</label>
                                    <select class="form-control" v-model="timezone" :class="{'is-invalid':invalid.timezone.length > 0}">
                                        <option value=""></option>
                                        <option v-for="option in timezoneOptions" :key="option" :value="option">{{option}}</option>
                                    </select>
                                    <div class="invalid-feedback">{{invalid.timezone}}</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <button type="submit" :disabled="!agree" class="btn btn-lg text-white btn-main-1 w-100">Sign up</button>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="agree-trems" v-model="agree">
                                <label class="form-check-label" for="agree-trems">
                                    I agree to the 
                                    <router-link :to="{ name: 'Terms' }"  class="text-main-1" target="_blank">Terms & Conditions</router-link>
                                </label>
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer justify-content-center p-3 p-md-4">
                        Have an account? 
                        <span class="text-main-1 ms-2 fw-bold cursor-pointer" @click="showLogin()">Login now</span>
                    </div>
                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import { mapState , mapMutations } from 'vuex'
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import { setCookie , getErrorMessage } from '@/assets/js/common.js';

export default {
    name: 'signup',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            name:"",
            email:"",
            password:"",
            confirm_password:"",
            timezone:"",
            agree:false,

            timezoneOptions:[],

            invalid:{
                name:"",
                email:"",
                password:"",
                confirm_password:"",
                timezone:"",
            },
        }
    },
    computed:{
        ...mapState(['modals'])
    },

    methods:{
        ...mapMutations(['set_page_loading']),

        showLogin(){
            this.modals.signup.hide();
            this.modals.login.show();
        },

        handleSignup() {
            this._reCAPTCHA((token) => {
                this.signup(token)
            })
        },

        signup(google_recaptcha_token){
            this.set_page_loading(true);
            this.clearInvalid();

            let data = {
                name: this.name,
                email: this.email,
                password: this.password,
                confirm_password: this.confirm_password,
                timezone: this.timezone,
                google_recaptcha_token,
            }

            this.axios.post('https://binaryflash.codewcy.com/api/site/signup', data).then(response => {
                this.set_page_loading(false);
                
                if(response.data.status === 'success'){
                    setCookie('tempEmail', this.email);
                    this.modals.signup.hide();
                    this.modals.signupSuccess.show();

                }else{
                    let { errorMsg, errorObj } = getErrorMessage(response.data.message);
                    
                    if(errorMsg === 'Invalid parameter'){
                        for(let key in errorObj){
                            this.invalid[key] = errorObj[key][0];
                        }
                    }else {
                        swal({
                            title: errorMsg,
                            icon: 'error',
                            button: "OK",
                        })
                    }
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })


            
        },

        getTimezones(){
            
            this.axios.get('https://binaryflash.codewcy.com/api/site/timezones').then(response => {
                const timezoneList = response.data.result.timezones;

                this.timezoneOptions = timezoneList;

                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                if (timezoneList.indexOf(timezone) !== -1) {
                    this.timezone = timezone;
                }
            }).catch((e)=>{
                this._handleCatch(e)
            })


        },

        clearInvalid(){
            for(let key in this.invalid){
                this.invalid[key] = "";
            }
        },

        clearInputs(){
            this.name = "";
            this.email = "";
            this.password = "";
            this.confirm_password = "";
            this.timezone = "";
            this.agree = false;
        },
        
    },
    mounted() {
        this.getTimezones();

        let signup = document.getElementById('signup');
        signup.addEventListener('hidden.bs.modal',() => {
            this.clearInvalid();
            this.clearInputs();
        })
        
    },
}
</script>
